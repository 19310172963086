/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.dropzone {
    text-align: center;
    padding: 20px;
    border: 2px solid #eeeeee;
    border-radius: 2px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

.dropzone.accepted {
    border-color: #bed9ff;
    background-color: #E7F1FF;
}

.ImageField .preview {
    margin-top: 10px;
    padding: 10px;
}

.ImageField .preview > div {
    background-repeat: no-repeat;
    background-size: contain;
}