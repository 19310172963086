/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.CardPreview {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    min-height: 20em;
    margin-bottom: 1em;
    border: $default-border;
    border-radius: $default-border-radius;
    padding: 1rem;
}
.CardPreview > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: clip;
}
