/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.center-vertical-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-height: $page-min-height;
}

.center-vertical {
    background-color: $background-color;
}

.center-vertical.xs {
    max-width: 320px !important;
}

@each $breakpoint, $container-max-width in $container-max-widths {
    .center-vertical.#{$breakpoint} {
        max-width: $container-max-width !important;
    }
}
