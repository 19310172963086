/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.navLogo {
    height: 48px;
    width: auto;
}

@media (max-width: 768px) {
    .AppNav .navbar-toggler {
        margin-right: 0.5rem;
    }
    .AppNav .navbar-collapse .nav-item {
        padding-left: 0.5rem;
    }
}
