/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.qrcode {
    display: inline-block;
    position: relative;
}

.qrcode > img {
    position: absolute;
    left: 35%;
    top: 35%;
    height: 30%;
    width: 30%;
}
