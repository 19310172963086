/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.CardStandard {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding-bottom: 50px;
}
.CardStandard p {
    color: #ddd;
    margin-bottom: 4px;
}
.CardStandard .header-image {
    height: 175px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.CardStandard .profile-image {
    margin-top: -84px;
    margin-bottom: 8px;
    width: 168px;
    height: 168px;
    border-radius: 84px;
    background-color: white;
    text-align: center;
    padding-top: 8px;
}
.CardStandard .profile-image img {
    height: 152px;
    width: 152px;
    border-radius: 76px;
}
