/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.ShareButtons {
    display: grid;
    width: 240px;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 30px;
    grid-auto-rows: minmax(60px, auto);
}

.ShareButtons a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    border-radius: 15px;
}

.ShareButtons a.icon {
}
.ShareButtons a.text {
    font-size: 24px;
}