/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

$page-min-height: 600px;
$page-background-color: #eee;
$background-color: #fff;

$default-border: 1px solid rgba(0,0,0,0.175);
$default-border-radius: 0.375rem;

$primary: #091D4F;
$secondary: #FFAF26;

.pointer {
    cursor: pointer;
}
