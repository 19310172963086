/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

.CardEditPage .card-preview {
    //position: sticky;
    //top: 0;
}

@media (max-width: 780px) {
    .CardEditPage .card-preview {
        margin-top: 20px;
    }
}